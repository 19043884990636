import { useQuery } from "@tanstack/react-query"

import { STATUS_CODE_OK } from "@l2r-front/l2r-query"

import { imageCollectionQueryKeys } from "./queryKeys"

async function queryFn(
    url,
) {
    const headers = {}
    const item = localStorage.getItem("authentication")
    if (item) {
        const authentication = JSON.parse(item)

        if (authentication.accessToken) {
            headers.Authorization = `Bearer ${authentication.accessToken}`
        }
    }

    const response = await fetch(url, { headers: headers })
    const data = await response.json()

    if (response.status === STATUS_CODE_OK) {
        const redirectedResponse = await fetch(data.image)
        return redirectedResponse.blob()
    } else {
        throw response
    }
}


export function usePhoto(photoUuid, config = {}) {
    const url = `${process.env["NX_API_L2R"]}photo/photos/${photoUuid}/`

    const { enabled } = config
    const queryKey = imageCollectionQueryKeys["photo"]({ uuid: photoUuid })

    return useQuery({
        queryKey,
        queryFn: () => queryFn(url),
        ...config,
        enabled: !!photoUuid && enabled,
    })
}